import React, { useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import "./CollapsibleBox.css";

export interface CollapsibleBoxProps {
    title: string;
    content: string;
}

const CollapsibleBox: React.FC<CollapsibleBoxProps> = ({ title, content }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className="collapsibleBox">
            <div className="collapsibleBox-header" onClick={handleToggleCollapse}>
                {isCollapsed ? <AiOutlinePlus className="expand-button" /> : <AiOutlineMinus className="expand-button" />}
                <span>{title}</span>
            </div>
            {!isCollapsed && (
                <div className="collapsibleBox-content" dangerouslySetInnerHTML={{ __html: content }}></div>
            )}
        </div>
    );
};

export default CollapsibleBox;
