import React from 'react';
import './App.css';
import Links, { Link } from './Links';
import Instructional from './Instructional';
import ReactModal from 'react-modal';
import List from './List';
import { Route, Routes } from 'react-router-dom';

ReactModal.setAppElement('#root');

function App() {

  const links: Link[] = [
    {
      title: "How to Use Plex",
      url: "/access"
    },
    {
      title: "Rules",
      url: "/rules"
    },
    {
      title: "Go to Plex",
      url: "https://app.plex.tv"
    },
    {
      title: "Make a Request",
      url: "https://request.olympus.nyc"
    },
    {
      title: "Server Status",
      url: "https://status.olympus.nyc"
    },
    {
      title: "Report an Issue",
      component: (
        <Instructional steps={[
          {
            title: "From the request site, search for the movie or TV show that's causing problems.",
            description: "This will open a new tab to the request site.",
            url: "https://request.olympus.nyc",
            buttonText: "Visit Request Site"
          },
          {
            title: "Click the 'Report Issue' button and fill in the details.",
            image: "report-an-issue.png"
          }
        ]} />
      )
    }
  ]

  const backButton = (
    <a href="/" className="back-button" style={{ backgroundColor: 'var(--color-accent-primary)' }}>Back</a>
  )

  return (
    <div className="App" id="App">
      <header className="App-header">
        <h3>Evan's Plex</h3>
        {/* <span className="server-status"><a href="https://status.olympus.nyc">Server Status</a></span> */}
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
      </header>

      <div className="App-body">
        
        <Routes>
          <Route path="/" element={
              <article className="links">
                <Links links={links} buttonColor="var(--color-accent-primary)" />
              </article>
          } />
          <Route path="/rules" element={
            <>
            {backButton}
            <List title="Rules:" items={[
              { content: "<i>Do not</i> discuss the server with people who do not have access." },
              { content: "<i>Do not</i> share your account with people outside of your household. Ever. I will find out. Access to my Plex server is a privilege granted only to my friends and family." },
              { content: "<i>Do</i> tell me if you come across a file that's in the wrong language or otherwise doesn't play correctly." },
              { content: "<i>Do</i> cancel your other streaming services and use Plex exclusively." },
              { content: "<i>Do not</i> be shy about requesting new content." },
              { content: "<i>Do</i> let me know if a TV show has missing episodes. The system is mostly automatic and I don't get notified when there are gaps." },
            ]} />
            </>
          } />
          <Route path="/access" element={
            <>
            {backButton}
            <List title="How to access:" items={[
              { content: "Follow the instructions in the email invitation from Plex to setup your account." },
              {
                content: "Download the Plex app on your device or visit <a target=\"_blank\" href=\"https://app.plex.tv\">app.plex.tv</a>.",
                notes: [
                  {
                    title: "Recommended Devices for Streaming Movies and TV Shows",
                    content: `
                      <p>For mobile streaming, <a target="_blank" href="https://www.plex.tv/apps-devices/">the first-party Plex app</a> works great on iOS, iPadOS, and Android.</p>
                      <p>For home viewing, use an Apple TV for the best experience.</p>
                      <p>Smart TVs tend to have trouble with higher quality content and don't receive software updates as often, but ultimately they'll work just fine 95% of the time.</p>
                      <p><a target="_blank" href="https://www.theverge.com/2023/12/1/23984444/amazon-fire-tv-autoplay-ads-on-startup">Fire TV</a>, and now <a target="_blank" href="https://www.theverge.com/2024/4/30/24145177/roku-home-screen-video-ads">Roku</a>, devices are just vessels for generating advertising revenue. They're cheap because your data is the product. I'd avoid them.</p>
                      <p><a href="https://www.plex.tv/apps-devices/">Click here</a> for a list of supported devices.</p>
                    `
                  },
                  {
                    title: "Here for Music?",
                    content: `
                      <p>While you can stream music with the standard Plex apps (and website), I recommended <a target="_blank" href="https://www.plex.tv/plexamp/">the Plexamp app</a>.</p>
                      <p>It's built for music streaming and supports iOS, Android, macOS, and Windows.</p>
                    `
                  }
                ]
              },
              { content: "If you're prompted to select a server, choose \"Orpheus\"." },
              { content: "Start streaming!" }
            ]} />
            </>
          } />
        </Routes>
      </div>
    </div>
  );
}

export default App;
