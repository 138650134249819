import React from 'react';
import './List.css';
import CollapsibleBox, { CollapsibleBoxProps } from './CollapsibleBox';

export interface ListItem {
  content: string;
  notes?: CollapsibleBoxProps[];
}

interface ListProps {
  title: string;
  items: ListItem[];
}

const List: React.FC<ListProps> = ({ title, items }) => {

  return (
    <div className="list-body">
      <h5>{title}</h5>
      <ol>
        {items.map((item, index) => (
          <li key={index}>
            <p dangerouslySetInnerHTML={{ __html: item.content }} />
            {item.notes && item.notes.map((note, index) => (
              <CollapsibleBox key={index} title={note.title} content={note.content} />
            ))}
          </li>
        ))}
      </ol>
    </div>
  );
}

export default List;
