import React from 'react';
import "./Instructional.css";

export interface Step {
    title: string;
    description?: string;
    image?: string;
    url?: string;
    buttonText?: string;
}

interface InstructionalProps {
    steps: Step[];
}

const Instructional: React.FC<InstructionalProps> = ({ steps }) => {
    return (
        <div className="Instructional">
            {steps.map((step, index) => (
                <div key={index}>
                    <h2>{index + 1}. {step.title}</h2>
                    {step.description && <p>{step.description}</p>}
                    {step.image && <img src={"/img/" + step.image} alt="Screenshot" />}
                    {step.url && <a target="_blank" rel="noreferrer" href={step.url}>{step.buttonText ?? "Click here"}</a>}
                </div>
            ))}
        </div>
    );
};

export default Instructional;
