/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode, useState } from 'react';
import Modal from 'react-modal';
import './Links.css';
import { AiOutlineClose } from "react-icons/ai";

export interface Link {
    title: string;
    url?: string;
    component?: ReactNode;
}

interface LinksProps {
    links: Link[];
    buttonColor: string;
}

const Links: React.FC<LinksProps> = ({ links, buttonColor }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);

    const openModal = (content: ReactNode) => {
        setModalContent(content);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <div className="links-container">
            {links.map((link, index) => (
                link.url ? 
                <a key={index} href={link.url} style={{ backgroundColor: buttonColor }} className="link">
                    {link.title}
                </a>
                :
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a key={index} onClick={() => openModal(link.component)} style={{ backgroundColor: buttonColor }} className="link">
                    {link.title}
                </a>
            ))}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Modal"
                className="instructional-modal"
                overlayClassName="instructional-modal-overlay"
            >
                {modalContent}
                <a className="close-button" onClick={closeModal}><AiOutlineClose /></a>
            </Modal>
        </div>
    );
};

export default Links;